import { tagService } from '@/services';
import { cloneDeep } from 'lodash';

export async function updateTags(holderEntityId, holderEntityService, oldTags, newTags) {
    const result = {
      hasError: false,
      errors: []
    }
    const tags = cloneDeep(newTags);
    const originTags = oldTags;

    // 1) Find the uuId of newly added tags if they exist in database
    // 2) Compile the toAdd list.
    // 3) Compile the toDelete list.
    // 4) Call remove API.
    // 5) Call create API.
    // Note: The sequence of the process (1, 2, 3) matters.

    // If any tag has been added to the list, create the link
    let toAdd = [];
    for (let i = 0; i < tags.length; i++) {
      if (!tags[i].uuId) {
        // get the uuId
        tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
          if (response.data.length !== 0) {
            return response.data[0].uuId;
          }
          return null;
        }).catch(e => {
          result.hasError = true;
          result.errors.push(e);
          return null;
        });
        
        if (tags[i].uuId === null) {
          tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
            if (response.data[response.data.jobCase].length !== 0) {
              return response.data[response.data.jobCase][0].uuId;
            }
            return null;
          }).catch(e => {
            result.hasError = true;
            result.errors.push(e);
            return null;
          });
        }
      }

      if (tags[i].uuId !== null &&
          !(originTags.map(s => s.uuId).includes(tags[i].uuId))) {
        toAdd.push({uuId: tags[i].uuId});
      }
    }

    // If any tag has been removed from the list, delete the link
    let toDelete = [];
    for (let i = 0; i < originTags.length; i++) {
      if (!(tags.map(s => s.uuId).includes(originTags[i].uuId))) {
        toDelete.push({uuId: originTags[i].uuId});
      }
    }

    if (toDelete.length > 0) {
      await holderEntityService.remove(holderEntityId, toDelete)
      .catch(e => {
        result.hasError = true;
        result.errors.push(e);
      })
    }

    if (toAdd.length > 0) {
      await holderEntityService.create(holderEntityId, toAdd)
      .catch(e => {
        result.hasError = true;
        result.errors.push(e)
      });
    }
    return result;
  }